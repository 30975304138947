import NavaparkBusiness1 from './Navapark Business Suites (1).webp'
import NavaparkBusiness2 from './Navapark Business Suites (2).webp'
import NavaparkBusiness3 from './Navapark Business Suites (3).webp'
import NavaparkBusiness4 from './Navapark Business Suites (4).webp'
import NavaparkBusiness5 from './Navapark Business Suites (5).webp'

const NavaparkBusinessimages =[
NavaparkBusiness1,NavaparkBusiness2,NavaparkBusiness3,NavaparkBusiness4,NavaparkBusiness5
]

export default NavaparkBusinessimages;
