import React from "react";
import "./other.scss";

import Hiera from "../../../media/Other BSD/Wynyard.png";

import Terravia from "../../../media/Other BSD/Terravia.webp";
import Eonna from "../../../media/Other BSD/Eonna.webp";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faArrowsUpDownLeftRight,
  faBed,
  faHouse,
  faToilet,
} from "@fortawesome/free-solid-svg-icons";

const Cardhome = () => {
  return (
    <div className="cardhomecontainers">
      <div className="cardtitle">Other Cluster in BSD</div>
      <div className="cardsection">
        <div className="cardhome">
          <div className="cardpicture">
            <img className="cardp" src={Terravia} alt="Terravia" />
          </div>
          <div className="carddes">
            <div className="title">Terravia</div>
            <div className="subtitle">Tanpa DP</div>
            <div className="subtitle">Cicilan Start 8 Juta/Bulan</div>
            <hr />
            <div className="gridspek">
              <FontAwesomeIcon color="#0A1134" icon={faArrowsUpDownLeftRight} />
              <span>66m² </span>
              <FontAwesomeIcon color="#0A1134" icon={faHouse} />
              <span>93m² </span>
              <FontAwesomeIcon color="#0A1134" icon={faBed} />
              <span>3 </span>
              <FontAwesomeIcon color="#0A1134" icon={faToilet} />
              <span>3 </span>
            </div>

            <div className="buttoncard">
              <button
                onClick={() =>
                  window.open(
                    "https://api.whatsapp.com/send/?phone=6282122005507&text=Halo%20Dean,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20produk%20rumah%20(Terravia)%20https://marketingbsdcity-navapark.com/&type=phone_number&app_absent=0",
                    "_blank"
                  )
                }
                className="wacard">
                Whatsapp
              </button>
            </div>
          </div>
        </div>
        <div className="cardhome">
          <div className="cardpicture">
            <img className="cardp" src={Hiera} alt="Lyndon" />
          </div>
          <div className="carddes">
            <div className="title">Hiera</div>
            <div className="subtitle">Tanpa DP</div>
            <div className="subtitle">Cicilan Start 14 Juta/Bulan</div>
            <hr />
            <div className="gridspek">
              <FontAwesomeIcon color="#0A1134" icon={faArrowsUpDownLeftRight} />
              <span>98m² </span>
              <FontAwesomeIcon color="#0A1134" icon={faHouse} />
              <span>118m² </span>
              <FontAwesomeIcon color="#0A1134" icon={faBed} />
              <span>3 </span>
              <FontAwesomeIcon color="#0A1134" icon={faToilet} />
              <span>3 </span>
            </div>
            <div className="buttoncard">
              <button
                onClick={() =>
                  window.open(
                    "https://api.whatsapp.com/send/?phone=6282122005507&text=Halo%20Dean,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20produk%20rumah%20(Hiera)%20https://bsdcity-navapark.id/&type=phone_number&app_absent=0",
                    "_blank"
                  )
                }
                className="wacard">
                Whatsapp
              </button>
            </div>
          </div>
        </div>
        <div className="cardhome">
          <div className="cardpicture">
            <img className="cardp" src={Eonna} alt="Layton" />
          </div>
          <div className="carddes">
            <div className="title">Eonna</div>
            <div className="subtitle">Tanpa DP</div>
            <div className="subtitle">Cicilan Start 22 Juta/Bulan</div>
            <hr />
            <div className="gridspek">
              <FontAwesomeIcon color="#0A1134" icon={faArrowsUpDownLeftRight} />
              <span>128m²</span>
              <FontAwesomeIcon color="#0A1134" icon={faHouse} />
              <span>192m²</span>
              <FontAwesomeIcon color="#0A1134" icon={faBed} />
              <span>4+1</span>
              <FontAwesomeIcon color="#0A1134" icon={faToilet} />
              <span>4+1</span>
            </div>
            <div className="buttoncard">
              <button
                onClick={() =>
                  window.open(
                    "https://api.whatsapp.com/send/?phone=6282122005507&text=Halo%20Dean,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20produk%20rumah%20(Eonna)%20https://marketingbsdcity-navapark.com/&type=phone_number&app_absent=0",
                    "_blank"
                  )
                }
                className="wacard">
                Whatsapp
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cardhome;
