import React from "react";
import Hero from "../../component/hero/Hero";
import headerlyndon from "../../media/Header Nava Web/Header Desktop/Header Lyndon.webp";
import lyndonlogo from "../../media/Logo/Lyndon.png";
import Producthero from "../../component/producthero/Producthero";
import Promo from "../../component/promo/Promo";
import promolyndonimg from "../../media/Banner Promo/Lyndon.webp";
import Productlogo from "../../component/productlogo/Productlogo";
import Cardlyndon from "../../section/Lyndon/cardlyndon/Cardlyndon";
import Surrounding from "../../component/surrounding/Surrounding";
import Map from "../../component/map/Map";
import lyndonmobile from "../../media/Header Nava Web/Header Mobile/Lyndon.webp";

const productdatalyndon = {
  src: lyndonlogo,
  header: "A Vision of Healthy Living",
  des: "Lyndon Navapark adalah sebuah perumahan mewah premium yang terletak di kawasan NavaPark BSD City, Tangerang. Dikembangkan oleh Sinarmas Land bekerja sama dengan Hongkong Land, Lyndon Navapark menawarkan hunian dengan arsitektur tropical modern yang eksklusif dan dilengkapi dengan fasilitas mewah seperti Botanical Park seluas 10 hektar dan Country Club. Perumahan ini terdiri dari tiga tipe unit, yaitu Type 12, Type 15, dan Type 19, masing-masing dilengkapi dengan fitur-fitur seperti double high ceiling entrance foyer, private lift, 5 bedrooms with en suite bathrooms, 2 powder rooms, spacious yard, dan mezzanine area pada level atas. ",
};

const promolyndon = {
  items: [
    "SIap Huni",
    "Free Lift",
    "Free Furnished",
    "Free Smarthome System",
    "Free Canopy",
    "Free AC",
    "Floor Marmer",
  ],
};
const bg = {
  backgroundColor: "white",
};
const color = {
  color: "#EBBB4A",
};
const walyndon = () => {
  const whatsappLink =
    "https://api.whatsapp.com/send/?phone=6282122005507&text=Halo%20Dean,%20Saya%C2%A0bisa%20minta%20detail%20Promo%20terbaru%20produk%20(Lyndon)%20Navapark%20https://bsdcity-navapark.id/&type=phone_number&app_absent=0";
  window.location.href = whatsappLink;
};
const Lyndon = () => {
  return (
    <div>
      <div id="lyndon"></div>
      <Hero desktopImage={headerlyndon} mobileImage={lyndonmobile} />
      <Producthero
        logoproduct={productdatalyndon.src}
        header={productdatalyndon.header}
        des={productdatalyndon.des}
      />
      <Promo data={promolyndon} promo={promolyndonimg} promoclick={walyndon} />
      <Productlogo prodlogo={lyndonlogo} />
      <Cardlyndon />
      <Surrounding bg={bg} color={color} />
      <Map />
    </div>
  );
};

export default Lyndon;
