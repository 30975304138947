import React from "react";
import Hero from "../../component/hero/Hero";
import laurerhero from "../../media/Header Nava Web/Header Desktop/Laurel Header.webp";
import Producthero from "../../component/producthero/Producthero";
import laurellogo from "../../media/Logo/Laurel.png";
import promolaurelimg from "../../media/Banner Promo/Laurel.webp";
import Promo from "../../component/promo/Promo";
import Productlogo from "../../component/productlogo/Productlogo";
import Cardlaurel from "../../section/Laurel/cardlaurel/Cardlaurel";
import Surrounding from "../../component/surrounding/Surrounding";
import Map from "../../component/map/Map";
import laurelmobile from "../../media/Header Nava Web/Header Mobile/Laurel.webp";

const Laurel = () => {
  const productdatalaurel = {
    src: laurellogo,
    header: "Home for Refined Living",
    des: "Dengan konsep Botanic Living, NavaPark memperkenalkan perkembangan baru. Laurel terletak di pusat NavaPark, di mana Botanic Park dan Country Club memiliki akses langsung ke area taman. Ini memiliki pemandangan danau dan memiliki 10.000 meter persegi area hijau yang mencakup jogging track, pavilion, lawn multifungsi, dan area fitness outdoor. Navapark BSD Country Club memiliki 16 fasilitas luar biasa, termasuk semi-Olympic Pool, lagoon Pool, swimming pool untuk anak-anak, aqua gym, pusat kebugaran outdoor, mini putting golf, taman anak indoor dan outdoor, dan lebih banyak lagi. Rumah Laurel Navapark memiliki fitur premium seperti full marmer, smart home system, smart door lock, free kitchen set, dan kitchen appliances.",
  };

  const laurelpromo = {
    items: [
      "Siap Huni",
      "Free Lift",
      "Free Canopy",
      "Free Furnished",
      "Sanitary by Kohler",
      "Free AC",
      "Free CCTV",
      "Free Smarthome System",
    ],
  };
  const bg = {
    backgroundColor: "white",
  };
  const color = {
    color: "#EBBB4A",
  };
  const walaurel = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6282122005507&text=Halo%20Dean,%20Saya%C2%A0bisa%20minta%20detail%20Promo%20terbaru%20produk%20(Laurel)%20Navapark%20https://bsdcity-navapark.id/&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };

  return (
    <div>
      <div id="laurel"></div>
      <Hero desktopImage={laurerhero} mobileImage={laurelmobile} />
      <Producthero
        logoproduct={productdatalaurel.src}
        header={productdatalaurel.header}
        des={productdatalaurel.des}
      />
      <Promo data={laurelpromo} promo={promolaurelimg} promoclick={walaurel} />
      <Productlogo prodlogo={laurellogo} />
      <Cardlaurel />
      <Surrounding bg={bg} color={color} />
      <Map />
    </div>
  );
};

export default Laurel;
