import React from "react";
import Hero from "../../component/hero/Hero";
import logo from "../../media/Header Nava Web/Header Desktop/Header Home.webp";
import SecondHero from "../../section/Home/secondhero/SecondHero";
import Promo from "../../component/promo/Promo";
import imagepromo from "../../media/Promo_home.webp";
import Facilities from "../../component/facilities/Facilities";
import Cardhome from "../../section/Home/cardhome/Cardhome";
import Other from "../../section/Home/other/other.jsx";
import Other1 from "../../section/Home/other1/other1.jsx";
import Sectionruko from "../../section/Home/sectionruko/Sectionruko";
import Surrounding from "../../component/surrounding/Surrounding";
import Map from "../../component/map/Map";
import homehero from "../../media/Header Nava Web/Header Mobile/Home.webp";
import ScrollToHashElement from "./ScrollToHashElement.js";

const Home = () => {
  const promoData = {
    items: [
      "Siap Huni",
      "Discount up to 10%",
      "Free Furnished",
      "Sanitary by Kohler",
      "Free AC",
      "Free Canopy",
      "Free Smarthome System",
    ],
  };
  const promohome = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6282122005507&text=Halo%20Dean,%20Saya%C2%A0bisa%20minta%20detail%20Promo%20terbaru%20produk%20Navapark%20https://bsdcity-navapark.id/&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };
  const bg = {
    backgroundColor: "#EBBB4A",
  };
  const color = {
    color: "white",
  };

  return (
    <div>
      <ScrollToHashElement />
      <Hero desktopImage={logo} mobileImage={homehero} />
      <SecondHero />
      <Promo data={promoData} promo={imagepromo} promoclick={promohome} />
      <Facilities />
      <Cardhome />
      <Other />
      <Other1 />
      <Sectionruko />
      <Surrounding bg={bg} color={color} />
      <Map />
    </div>
  );
};

export default Home;
