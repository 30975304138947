import React from "react";
import "./other1.scss";

import Armont from "../../../media/Other BSD/Armont.webp";
import Enchante from "../../../media/Other BSD/Enchante.webp";
import Tresor from "../../../media/Other BSD/Tresor.webp";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faArrowsUpDownLeftRight,
  faBed,
  faHouse,
  faLayerGroup,
  faToilet,
} from "@fortawesome/free-solid-svg-icons";

const Cardhome = () => {
  return (
    <div className="cardhomecontainers1">
      <div className="cardsection">
        <div className="cardhome">
          <div className="cardpicture">
            <img className="cardp" src={Armont} alt="Terravia" />
          </div>
          <div className="carddes">
            <div className="title">Armont</div>
            <div className="subtitle">Subsidi DP</div>
            <div className="subtitle">Cicilan Start 24 Juta/Bulan</div>
            <hr />
            <div className="gridspek">
              <FontAwesomeIcon color="#0A1134" icon={faArrowsUpDownLeftRight} />
              <span>200m² </span>
              <FontAwesomeIcon color="#0A1134" icon={faHouse} />
              <span>380m² </span>
              <FontAwesomeIcon color="#0A1134" icon={faBed} />
              <span>5+1</span>
              <FontAwesomeIcon color="#0A1134" icon={faToilet} />
              <span>6+1</span>
            </div>

            <div className="buttoncard">
              <button
                onClick={() =>
                  window.open(
                    "https://api.whatsapp.com/send/?phone=6282122005507&text=Halo%20Dean,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20produk%20rumah%20(Armont)%20https://marketingbsdcity-navapark.com/&type=phone_number&app_absent=0",
                    "_blank"
                  )
                }
                className="wacard">
                Whatsapp
              </button>
            </div>
          </div>
        </div>
        <div className="cardhome">
          <div className="cardpicture">
            <img className="cardp" src={Tresor} alt="Lyndon" />
          </div>
          <div className="carddes">
            <div className="title">Tresor</div>
            <div className="subtitle">Subsidi DP</div>
            <div className="subtitle">Cicilan Start 50 Juta/Bulan</div>
            <hr />
            <div className="gridspek">
              <FontAwesomeIcon color="#0A1134" icon={faArrowsUpDownLeftRight} />
              <span>440m² </span>
              <FontAwesomeIcon color="#0A1134" icon={faHouse} />
              <span>412m² </span>
              <FontAwesomeIcon color="#0A1134" icon={faBed} />
              <span>4 </span>
              <FontAwesomeIcon color="#0A1134" icon={faToilet} />
              <span>6 </span>
            </div>
            <div className="buttoncard">
              <button
                onClick={() =>
                  window.open(
                    "https://api.whatsapp.com/send/?phone=6282122005507&text=Halo%20Dean,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20produk%20rumah%20(Tressor)%20https://bsdcity-navapark.id/&type=phone_number&app_absent=0",
                    "_blank"
                  )
                }
                className="wacard">
                Whatsapp
              </button>
            </div>
          </div>
        </div>
        <div className="cardhome">
          <div className="cardpicture">
            <img className="cardp" src={Enchante} alt="Layton" />
          </div>
          <div className="carddes">
            <div className="title">Enchante</div>
            <div className="subtitle">Tanpa DP</div>
            <div className="subtitle">Cicilan Start 54 Juta/Bulan</div>
            <hr />
            <div className="gridspek">
              <FontAwesomeIcon color="#0A1134" icon={faArrowsUpDownLeftRight} />
              <span>340m²</span>
              <FontAwesomeIcon color="#0A1134" icon={faHouse} />
              <span>398m²</span>
              <FontAwesomeIcon color="#0A1134" icon={faBed} />
              <span>4+1</span>
              <FontAwesomeIcon color="#0A1134" icon={faToilet} />
              <span>6+1</span>
            </div>
            <div className="buttoncard">
              <button
                onClick={() =>
                  window.open(
                    "https://api.whatsapp.com/send/?phone=6282122005507&text=Halo%20Dean,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20produk%20rumah%20(Enchante)%20https://marketingbsdcity-navapark.com/&type=phone_number&app_absent=0",
                    "_blank"
                  )
                }
                className="wacard">
                Whatsapp
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cardhome;
